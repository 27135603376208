import {
  createAdvertismentAction,
  CreateAdvertismentActionTypes,
  CreateAdvertismentState,
} from '../../../types/createAdvertisment'

const initialState: CreateAdvertismentState = {
  isCreated: false,
  loading: false,
  error: null,
}

export const createAdvertismentReducer = (
  state = initialState,
  action: createAdvertismentAction
): CreateAdvertismentState => {
  switch (action.type) {
    case CreateAdvertismentActionTypes.CREATE_ADVERTISMENT:
      return { isCreated: false, loading: true, error: null }
    case CreateAdvertismentActionTypes.CREATE_ADVERTISMENT_SUCCESS:
      return { isCreated: action.payload, loading: false, error: null }
    case CreateAdvertismentActionTypes.CREATE_ADVERTISMENT_ERROR:
      return { isCreated: false, loading: false, error: action.payload }
    default:
      return state
  }
}
