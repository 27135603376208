export interface createFeedbackState {
  isCreated: boolean
  loading: boolean
  error: null | string
}

export enum CreateFeedbackTypes {
  CREATE_FEEDBACK = 'CREATE_FEEDBACK',
  CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS',
  CREATE_FEEDBACK_ERROR = 'CREATE_FEEDBACK_ERROR',
}

interface CreateFeedbackAction {
  type: CreateFeedbackTypes.CREATE_FEEDBACK
}

export interface CreateFeedbackActionSuccess {
  type: CreateFeedbackTypes.CREATE_FEEDBACK_SUCCESS
  payload: any
}

interface CreateFeedbackActionError {
  type: CreateFeedbackTypes.CREATE_FEEDBACK_ERROR
  payload: string
}

export type CreateFeedbackActions =
  | CreateFeedbackAction
  | CreateFeedbackActionSuccess
  | CreateFeedbackActionError
