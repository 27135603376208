export interface createOrderState {
  isCreated: boolean
  loading: boolean
  error: null | string
}

export enum CreateOrderActionTypes {
  CREATE_ORDER = 'CREATE_ORDER',
  CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR',
}

interface CreateOrderAction {
  type: CreateOrderActionTypes.CREATE_ORDER
}

interface CreateOrderActionSuccess {
  type: CreateOrderActionTypes.CREATE_ORDER_SUCCESS
  payload: boolean
}

interface CreateOrderActionError {
  type: CreateOrderActionTypes.CREATE_ORDER_ERROR
  payload: string
}

export type CreateOrderActions =
  | CreateOrderAction
  | CreateOrderActionSuccess
  | CreateOrderActionError
