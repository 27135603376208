import {
  OrdersStatusAction,
  OrdersStatusActionTypes,
  ordersStatusState,
} from '../../types/ordersStatus'

const initialState: ordersStatusState = {
  ordersStatus: 'pending',
}

export const ordersStatusReducer = (
  state = initialState,
  action: OrdersStatusAction
): ordersStatusState => {
  switch (action.type) {
    case OrdersStatusActionTypes.SET_ORDERS_STATUS:
      return { ordersStatus: action.payload }
    default:
      return state
  }
}
