const config = {
  firebase: {
    apiKey: 'AIzaSyA_3LLmtS0kuKplnQJAkRhSBSvnXg4fNko',
    authDomain: 'baidarka-project-dev.firebaseapp.com',
    projectId: 'baidarka-project-dev',
    storageBucket: 'baidarka-project-dev.appspot.com',
    messagingSenderId: '536028367878',
    appId: '1:536028367878:web:ecf936a2b7b34e2875c787',
  },
}

export default config
