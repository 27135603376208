import {
  CardsActions,
  CardsActionTypes,
  CardsState,
  ICard,
  ICardsFilters,
} from '../../types/cards'

const initialState: CardsState = {
  __cards: [],
  cards: [],
  filters: {
    byTop: false,
    byOrganization: '',
  },
  loading: false,
  approveLoading: false,
  denyLoading: false,
  deleteLoading: false,
  statusLoading: false,
  deleteError: '',
  statusError: '',
  approveError: '',
  denyError: '',
  error: '',
}

export const cardsReducer = (
  state = initialState,
  action: CardsActions
): CardsState => {
  switch (action.type) {
    case CardsActionTypes.FETCH_CARDS:
      return { ...state, loading: true }
    case CardsActionTypes.FETCH_CARDS_SUCESS:
      return {
        ...state,
        loading: false,
        __cards: [...action.payload],
        cards: applyFilters(action.payload, state.filters),
      }
    case CardsActionTypes.FETCH_CARDS_ERROR:
      return { ...state, loading: false }
    case CardsActionTypes.APPROVE_CARD:
      return { ...state, approveLoading: true }
    case CardsActionTypes.APPROVE_CARD_SUCCESS:
      return {
        ...state,
        approveLoading: false,
        cards: state.cards.filter((el) => el.uid !== action.payload),
      }
    case CardsActionTypes.APPROVE_CARD_ERROR:
      return { ...state, approveLoading: false, approveError: action.payload }
    case CardsActionTypes.DENY_CARD:
      return { ...state, denyLoading: true }
    case CardsActionTypes.DENY_CARD_SUCCESS:
      return {
        ...state,
        denyLoading: false,
        cards: state.cards.filter((el) => el.uid !== action.payload),
      }
    case CardsActionTypes.DENY_CARD_ERROR:
      return { ...state, denyLoading: false, denyError: action.payload }
    case CardsActionTypes.DELETE_CARD:
      return { ...state, deleteLoading: true }
    case CardsActionTypes.DELETE_CARD_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        cards: state.cards.filter((el) => el.uid !== action.payload),
      }
    case CardsActionTypes.DELETE_CARD_ERROR:
      return { ...state, deleteLoading: false, deleteError: action.payload }
    case CardsActionTypes.STATUS_CARD:
      return { ...state, statusLoading: true }
    case CardsActionTypes.STATUS_CARD_SUCCESS: {
      const { isTopRated, status, uid } = action.payload

      state.__cards.find((card) => {
        if (card.uid === uid) {
          card.isTopRated = isTopRated
          card.status = status

          return true
        }
        return false
      })

      return { ...state, statusLoading: false }
    }
    case CardsActionTypes.STATUS_CARD_ERROR:
      return { ...state, statusLoading: false, statusError: action.payload }

    case CardsActionTypes.CARDS_FILTERS: {
      const newFilters = megreFilters(state.filters, action.payload)

      return {
        ...state,
        filters: newFilters,
        cards: applyFilters(state.__cards, newFilters),
      }
    }
    default:
      return state
  }
}

function applyFilters(
  cards: Array<ICard>,
  filters: ICardsFilters
): Array<ICard> {
  const filteredCards = Object.keys(filters).reduce((prevFiltered, key) => {
    switch (key) {
      case 'byTop': {
        return filters.byTop
          ? prevFiltered.filter((card) => card.isTopRated)
          : prevFiltered
      }

      case 'byOrganization': {
        if (filters.byOrganization) {
          return prevFiltered.filter(
            (card) => card.owner === filters.byOrganization
          )
        }
      }
    }

    return prevFiltered
  }, cards)

  return filteredCards
}

function megreFilters(
  prev: ICardsFilters,
  current: ICardsFilters
): ICardsFilters {
  return { ...prev, ...current }
}
