interface ICardOwner {
  avatar: string
  firstName: string
  number: string
}

export interface ICard {
  uid: string
  name: string
  spaceAmount: number
  owner: ICardOwner
  price: number
  oneDay: boolean
  createdDate: string
  description: string
  status: string
  isTopRated: boolean
  city: string
  manyDays: boolean
  withNight: boolean
  withoutNight: boolean
  withTransfer: boolean
  withoutTransfer: boolean
  instagram: string
  vk: string
  twitter: string
  facebook: string
  images: Array<string>
}

export interface CardState {
  data: ICard
  loading: boolean
  error: string
  ownerLoading: boolean
  ownerError: string
}

export enum CardActionTypes {
  FETCH_CARD = 'FETCH_CARD',
  FETCH_CARD_SUCESS = 'FETCH_CARD_SUCESS',
  FETCH_CARD_ERROR = 'FETCH_CARD_ERROR',
  FETCH_CARD_OWNER_SUCCESS = 'FETCH_CARD_OWNER_SUCCESS_ACTION',
  FETCH_CARD_OWNER_ERROR = 'FETCH_CARD_OWNER_ERROR_ACTION',
  FETCH_CARD_OWNER = 'FETCH_CARD_OWNER_ACTION',
}

interface CardAction {
  type: CardActionTypes.FETCH_CARD
}

interface CardActionSuccess {
  type: CardActionTypes.FETCH_CARD_SUCESS
  payload: ICard
}

interface CardActionError {
  type: CardActionTypes.FETCH_CARD_ERROR
  payload: string
}

interface CardOwnerAction {
  type: CardActionTypes.FETCH_CARD_OWNER
}

interface CardOwnerActionSuccess {
  type: CardActionTypes.FETCH_CARD_OWNER_SUCCESS
  payload: ICardOwner
}

interface CardWonerActionError {
  type: CardActionTypes.FETCH_CARD_OWNER_ERROR
  payload: string
}

export type CardActions =
  | CardAction
  | CardActionSuccess
  | CardActionError
  | CardOwnerAction
  | CardOwnerActionSuccess
  | CardWonerActionError
