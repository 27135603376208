import {
  createOrderState,
  CreateOrderActions,
  CreateOrderActionTypes,
} from '../../../types/createOrder'

const initialState: createOrderState = {
  isCreated: false,
  loading: false,
  error: null,
}

export const createOrderReducer = (
  state = initialState,
  action: CreateOrderActions
): createOrderState => {
  switch (action.type) {
    case CreateOrderActionTypes.CREATE_ORDER:
      return { isCreated: false, loading: true, error: null }
    case CreateOrderActionTypes.CREATE_ORDER_SUCCESS:
      return { isCreated: action.payload, loading: false, error: null }
    case CreateOrderActionTypes.CREATE_ORDER_ERROR:
      return { isCreated: false, loading: false, error: action.payload }
    default:
      return state
  }
}
