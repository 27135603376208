import {
  updateUserEmailActions,
  updateUserEmailActionTypes,
  updateUserEmailState,
} from '../../../types/updateUserEmail'

const initialState: updateUserEmailState = {
  isUpdated: false,
  loading: false,
  error: null,
}

export const updateUserEmailReducer = (
  state = initialState,
  action: updateUserEmailActions
): updateUserEmailState => {
  switch (action.type) {
    case updateUserEmailActionTypes.UPDATE_USER_EMAIL:
      return { isUpdated: false, loading: true, error: null }
    case updateUserEmailActionTypes.UPDATE_USER_EMAIL_SUCCESS:
      return { isUpdated: action.payload, loading: false, error: null }
    case updateUserEmailActionTypes.UPDATE_USER_EMAIL_ERROR:
      return { isUpdated: false, loading: false, error: action.payload }
    default:
      return state
  }
}
