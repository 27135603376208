export interface categoryState {
  categoryName: string
  pathName: string | null
}

export enum CategoryActionTypes {
  SET_CATEGORY = 'SET_CATEGORY',
}

type CategoryPayload = {
  categoryName: string
  pathName: string
}

interface SetCategoryAction {
  type: CategoryActionTypes.SET_CATEGORY
  payload: CategoryPayload
}

export type CategoryAction = SetCategoryAction
