import {
  servicesState,
  servicesActions,
  servicesActionTypes,
} from '../../types/services'

const initialState: servicesState = {
  services: [],
  loading: false,
  deleteLoading: false,
  stopLoading: false,
  updateLoading: false,
  deleteError: '',
  stopError: '',
  updateError: '',
  error: null,
  total: 0,
}

export const servicesReducer = (
  state = initialState,
  action: servicesActions
): servicesState => {
  switch (action.type) {
    case servicesActionTypes.SERVICES:
      return { ...state, loading: true }
    case servicesActionTypes.SERVICES_SUCCESS: {
      const { items, end, start } = action.payload

      return { ...state, services: items.slice(start, end), loading: false }
    }

    case servicesActionTypes.TOTAL_SERVICES: {
      return {
        ...state,
        total: action.payload,
      }
    }

    case servicesActionTypes.SERVICES_ERROR:
      return { ...state, loading: false, error: action.payload }
    case servicesActionTypes.DELETE_SERVICE:
      return { ...state, deleteLoading: true }
    case servicesActionTypes.DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        services: state.services.filter((el) => el.uid !== action.payload),
      }
    case servicesActionTypes.DELETE_SERVICE_ERROR:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.payload,
      }
    case servicesActionTypes.UPDATE_SERVICE:
      return {
        ...state,
        updateLoading: true,
      }
    case servicesActionTypes.UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      }
    case servicesActionTypes.UPDATE_SERVICE_ERROR:
      return {
        ...state,
        updateLoading: false,
        updateError: action.payload,
      }
    case servicesActionTypes.STOP_SERVICE:
      return {
        ...state,
        stopLoading: true,
      }
    case servicesActionTypes.STOP_SERVICE_SUCCESS:
      return {
        ...state,
        stopLoading: false,
        services: state.services.filter((el) => el.uid !== action.payload),
      }
    case servicesActionTypes.STOP_SERVICE_ERROR:
      return {
        ...state,
        stopLoading: false,
        stopError: action.payload,
      }
    default:
      return state
  }
}
