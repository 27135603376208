export interface CreatePostState {
  isCreated: boolean
  loading: boolean
  error: null | string
}

export enum CreatePostActionTypes {
  CREATE_POST = 'CREATE_POST',
  CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS',
  CREATE_POST_ERROR = 'CREATE_POST_ERROR',
}

interface CreatePostAction {
  type: CreatePostActionTypes.CREATE_POST
}

interface CreatePostActionSuccess {
  type: CreatePostActionTypes.CREATE_POST_SUCCESS
  payload: true
}

interface CreatePostActionError {
  type: CreatePostActionTypes.CREATE_POST_ERROR
  payload: string
}

export type CreatePostActions =
  | CreatePostAction
  | CreatePostActionSuccess
  | CreatePostActionError
