export interface accountUserState {
  user: Record<string, unknown>
  loading: boolean
  loaded: boolean
  error: null | string
}

export enum accountUserActionTypes {
  USER = 'USER',
  USER_SUCCESS = 'USER_SUCCESS',
  USER_ERROR = 'USER_ERROR',
}

interface accountUserAction {
  type: accountUserActionTypes.USER
}

interface accountUserActionSuccess {
  type: accountUserActionTypes.USER_SUCCESS
  payload: Record<string, unknown>
}

interface accountUserActionError {
  type: accountUserActionTypes.USER_ERROR
  payload: string
}

export type accountUserActions =
  | accountUserAction
  | accountUserActionSuccess
  | accountUserActionError
