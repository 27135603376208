import {
  tripItemStatusActionTypes,
  tripItemStatusState,
  tripItemStatustActions,
} from '../../types/tripItemStatus'

const initialState: tripItemStatusState = {
  loading: false,
  error: null,
}

export const tripItemStatusReducer = (
  state = initialState,
  action: tripItemStatustActions
): tripItemStatusState => {
  switch (action.type) {
    case tripItemStatusActionTypes.TRIP_ITEM_STATUS:
      return {
        loading: true,
        error: null,
      }
    case tripItemStatusActionTypes.TRIP_ITEM_STATUS_SUCCESS:
      return {
        loading: false,
        error: null,
      }
    case tripItemStatusActionTypes.TRIP_ITEM_STATUS_ERROR:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
