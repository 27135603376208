import {
  CategoryAction,
  CategoryActionTypes,
  categoryState,
} from '../../types/category'

const initialState: categoryState = {
  categoryName: '',
  pathName: null,
}

export const categoryReducer = (
  state = initialState,
  action: CategoryAction
) => {
  switch (action.type) {
    case CategoryActionTypes.SET_CATEGORY:
      return {
        categoryName: action.payload.categoryName,
        pathName: action.payload.pathName,
      }

    default:
      return state
  }
}
