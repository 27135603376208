import { TypeOrdersPipeline } from './ordersStatus'

export interface ordersState {
  orders: Array<{
    id: string
    profileUri: string
    clientName: string
    clientNumber: string
    date: string
    finalPrice: number
    personAmount: number
    status: TypeOrdersPipeline
    title: string
  }>
  total: number
  loading: boolean
  error: null | string
}

export enum ordersActionTypes {
  ORDERS = 'ORDERS',
  ORDERS_SUCCESS = 'ORDERS_SUCCESS',
  ORDERS_ERROR = 'ORDERS_ERROR',
  DELETE_ORDER = 'DELETE_ORDER',
  ORDERS_TOTAL = 'ORDERS_TOTAL',
}

interface ordersAction {
  type: ordersActionTypes.ORDERS
}

interface ordersActionSuccess {
  type: ordersActionTypes.ORDERS_SUCCESS
  payload: {
    items: []
    start?: number
    end?: number
  }
}

interface ordersActionTotal {
  type: ordersActionTypes.ORDERS_TOTAL
  payload: number
}

interface ordersActionError {
  type: ordersActionTypes.ORDERS_ERROR
  payload: string
}

interface tripsActionDeleteOrder {
  type: ordersActionTypes.DELETE_ORDER
  payload: {
    id: string
  }
}

export type ordersActions =
  | ordersAction
  | ordersActionSuccess
  | ordersActionError
  | tripsActionDeleteOrder
  | ordersActionTotal
