import {
  TripsStatusAction,
  TripsStatusActionTypes,
  tripsStatusState,
} from '../../types/tripsStatus'

const initialState: tripsStatusState = {
  tripsStatus: 'pending',
}

export const tripsStatusReducer = (
  state = initialState,
  action: TripsStatusAction
): tripsStatusState => {
  switch (action.type) {
    case TripsStatusActionTypes.SET_TRIPS_STATUS:
      return { tripsStatus: action.payload }
    default:
      return state
  }
}
