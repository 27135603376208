import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import config from './config'

const Firebase = initializeApp(config.firebase)

export const auth = getAuth(Firebase)
export const db = getFirestore(Firebase)
export const storage = getStorage(Firebase)

export default Firebase
