export interface updateAccountUserState {
  isUpdated: boolean
  loading: boolean
  error: null | string
}

export enum updateAccountUserActionTypes {
  UPDATE_ACCOUNT_USER = 'UPDATE_ACCOUNT_USER',
  UPDATE_ACCOUNT_USER_SUCCESS = 'UPDATE_ACCOUNT_USER_SUCCESS',
  UPDATE_ACCOUNT_USER_ERROR = 'UPDATE_ACCOUNT_USER_ERROR',
}

interface updateAccountUserAction {
  type: updateAccountUserActionTypes.UPDATE_ACCOUNT_USER
}

interface updateAccountUserActionSuccess {
  type: updateAccountUserActionTypes.UPDATE_ACCOUNT_USER_SUCCESS
  payload: boolean
}

interface updateAccountUserActionError {
  type: updateAccountUserActionTypes.UPDATE_ACCOUNT_USER_ERROR
  payload: string
}

export type updateAccountUserActions =
  | updateAccountUserAction
  | updateAccountUserActionSuccess
  | updateAccountUserActionError
