/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { favouriteActionTypes } from '../../types/favourites'
import {
  postsActions,
  postsActionTypes,
  PostsFiltersType,
  PostsSortsType,
  postsState,
} from '../../types/posts'

const initialState: postsState = {
  posts: [],
  __posts: [],
  sorting: {},
  filters: {},
  loading: false,
  loaded: false,
  error: null,
}

export const postsRedcer = (
  state = initialState,
  action: postsActions
): postsState => {
  switch (action.type) {
    case postsActionTypes.POSTS:
      return {
        posts: state.posts,
        __posts: state.__posts,
        loading: true,
        error: null,
        sorting: state.sorting,
        filters: state.filters,
        loaded: state.loaded,
      }

    case postsActionTypes.POSTS_SUCCESS:
      const { items } = action.payload

      return {
        posts: applySortingOptions(
          applyFiltersOptions(items, state.filters),
          state.sorting
        ),
        __posts: items,
        loading: false,
        error: null,
        loaded: true,
        sorting: state.sorting,
        filters: state.filters,
      }

    case postsActionTypes.POSTS_ERROR:
      return {
        posts: [],
        __posts: [],
        loading: false,
        error: action.payload,
        loaded: false,
        sorting: state.sorting,
        filters: state.filters,
      }

    case postsActionTypes.POSTS_SET_SORTS: {
      const sortOptions = mergeSortsOptions(state.sorting, action.payload)
      return {
        error: null,
        loading: false,
        posts: applySortingOptions(
          applyFiltersOptions(state.__posts, state.filters),
          sortOptions
        ),
        __posts: state.__posts,
        loaded: state.loaded,
        sorting: sortOptions,
        filters: state.filters,
      }
    }

    case favouriteActionTypes.FAVOURITES_CHANGE_STATUS: {
      const { cardId } = action.payload
      const post = state.__posts.find((post) => post.uid === cardId)

      if (post) {
        post.isFavourite = !post.isFavourite
      }
      return {
        ...state,
        __posts: [...state.__posts],
        posts: [...state.posts],
        error: null,
        loading: false,
      }
    }

    case postsActionTypes.POSTS_SET_FILTERS: {
      const filtersOptions = mergeFiltersOptions(state.filters, {
        ...state.filters,
        ...action.payload,
      })
      return {
        error: null,
        loading: false,
        posts: applySortingOptions(
          applyFiltersOptions(state.__posts, filtersOptions),
          state.sorting
        ),
        loaded: state.loaded,
        __posts: state.__posts,
        sorting: state.sorting,
        filters: filtersOptions,
      }
    }
    default:
      return state
  }
}

function applyFiltersOptions(
  posts: Array<any>,
  filtersOptions: PostsFiltersType
) {
  const filteredPosts = Object.keys(filtersOptions).reduce(
    (filtered: Array<any>, key: string) => {
      if (filtersOptions[key] === undefined) {
        return filtered
      }
      switch (key) {
        case 'name': {
          return filtered.filter((value) =>
            (value.name || '')
              .toLowerCase()
              .includes(filtersOptions.name!.toLowerCase())
          )
        }
        case 'minPrice': {
          return filtered.filter(
            (value) =>
              (Number(value.price) || 0) >=
              (Number(filtersOptions.minPrice) || 0)
          )
        }
        case 'maxPrice': {
          return filtered.filter(
            (value) =>
              (Number(value.price) || 0) <=
              (Number(filtersOptions.maxPrice) || 0)
          )
        }
        case 'oneDayTour': {
          return filtered.filter(
            (value) =>
              Boolean(value.oneDay) == Boolean(filtersOptions.oneDayTour)
          )
        }
        case 'manyDaysTour': {
          return filtered.filter(
            (value) =>
              Boolean(value.manyDays) == Boolean(filtersOptions.manyDaysTour)
          )
        }
        case 'date': {
          return filtered
        }
        case 'city': {
          if (filtersOptions.city === 'Все города') {
            return filtered
          }
          return filtered.filter(
            (value) =>
              value.city == filtersOptions.city || value.city == 'Все города'
          )
        }
        case 'personAmount': {
          return filtered.filter(
            (value) =>
              (Number(value.spaceAmount) || 0) >=
              (Number(filtersOptions.personAmount) || 0)
          )
        }
        case 'withNight': {
          return filtered.filter(
            (value) =>
              Boolean(value.withNight) == Boolean(filtersOptions.withNight)
          )
        }
        case 'withoutNight': {
          return filtered.filter(
            (value) =>
              Boolean(value.withoutNight) ==
              Boolean(filtersOptions.withoutNight)
          )
        }
        case 'withTransfer': {
          return filtered.filter(
            (value) =>
              Boolean(value.withTransfer) ==
              Boolean(filtersOptions.withTransfer)
          )
        }
        case 'withoutTransfer': {
          return filtered.filter(
            (value) =>
              Boolean(value.withoutTransfer) ==
              Boolean(filtersOptions.withoutTransfer)
          )
        }
      }

      return filtered
    },
    posts
  )

  return filteredPosts
}

function applySortingOptions(posts: Array<any>, sortOptions: PostsSortsType) {
  const { price } = sortOptions

  if (price) {
    posts.sort((a, b) =>
      price === 'asc' ? a.price - b.price : b.price - a.price
    )
  }
  // заглушка
  // if (reit) {
  //   posts.sort((a, b) => (reit === 'asc' ? a - b : b - a))
  // }

  return posts
}

function mergeFiltersOptions(
  prev: PostsFiltersType,
  current: PostsFiltersType
): PostsFiltersType {
  return {
    ...prev,
    ...current,
  }
}

function mergeSortsOptions(
  prev: PostsSortsType,
  current: PostsSortsType
): PostsSortsType {
  const { price, reit } = current

  return {
    price: price
      ? price !== 'none'
        ? price === prev.price
          ? undefined
          : price
        : undefined
      : prev.price,
    reit: reit
      ? reit !== 'none'
        ? reit === prev.reit
          ? undefined
          : reit
        : undefined
      : prev.reit,
  }
}
