import { tripsState, tripsActions, tripsActionTypes } from '../../types/trips'

const initialState: tripsState = {
  trips: [],
  loading: false,
  error: null,
  total: 0,
}

export const tripsReducer = (
  state = initialState,
  action: tripsActions
): tripsState => {
  switch (action.type) {
    case tripsActionTypes.TRIPS:
      return {
        trips: state.trips,
        loading: true,
        error: null,
        total: state.total,
      }

    case tripsActionTypes.TRIPS_SUCCESS: {
      const { items, end, start } = action.payload

      const trips = items.slice(start, end)

      return {
        trips,
        loading: false,
        error: null,
        total: state.total,
      }
    }
    case tripsActionTypes.DELETE_TRIP:
      return {
        trips: state.trips.filter((trip) => trip.id !== action.payload.id),
        loading: false,
        error: null,
        total: state.total - 1,
      }

    case tripsActionTypes.TRIPS_TOTAL:
      return {
        trips: state.trips,
        loading: state.loading,
        error: null,
        total: action.payload,
      }

    case tripsActionTypes.TRIPS_ERROR:
      return {
        trips: [],
        loading: false,
        error: action.payload,
        total: 0,
      }
    default:
      return state
  }
}
