import {
  accountUserActions,
  accountUserActionTypes,
  accountUserState,
} from '../../types/accountUser'

const initialState: accountUserState = {
  user: {},
  loading: false,
  error: null,
}

export const accountUserReducer = (
  state = initialState,
  action: accountUserActions
): accountUserState => {
  switch (action.type) {
    case accountUserActionTypes.USER:
      return { user: {}, loading: true, error: null, loaded: false }
    case accountUserActionTypes.USER_SUCCESS:
      console.log(action.payload)

      return {
        user: { ...state.user, ...action.payload },
        loading: false,
        loaded: true,
        error: null,
      }
    case accountUserActionTypes.USER_ERROR:
      return { user: {}, loading: false, error: action.payload, loaded: false }
    default:
      return state
  }
}
