import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { Dispatch } from 'redux'
import { store } from '..'
import { auth, db } from '../../firebase/config/firebase'

import { LoginAction, SignInActionTypes } from '../../types/signIn'

export const signIn = (email: string, password: string) => {
  return async function logIn(dispatch: Dispatch<LoginAction>) {
    try {
      dispatch({
        type: SignInActionTypes.SIGN_IN,
      })
      const res = await signInWithEmailAndPassword(auth, email, password)
      const userRef = doc(db, 'users', res.user.uid)
      const data = await getDoc(userRef)
      const role = data.data()
      dispatch({
        type: SignInActionTypes.SIGN_IN_SUCCESS,
        payload: {
          isSignIn: true,
          role: role!.role,
        },
      })
    } catch (e) {
      dispatch({
        type: SignInActionTypes.SIGN_IN_ERROR,
        payload: 'Ошибка аутентификации!',
      })
    }
  }
}

export async function logOut() {
  try {
    store.dispatch({
      type: SignInActionTypes.SIGN_OUT,
    })
    await signOut(auth)
    store.dispatch({
      type: SignInActionTypes.SIGN_OUT_SUCCESS,
      payload: {
        isSignIn: false,
        role: undefined,
        loading: false,
        error: '',
      },
    })
  } catch (e) {
    store.dispatch({
      type: SignInActionTypes.SIGN_OUT_ERROR,
      payload: 'Ошибка!',
    })
  }
}

export async function keepSignIn(user: any, state: any) {
  try {
    state.dispatch({
      type: SignInActionTypes.SIGN_IN,
    })
    const userRef = doc(db, 'users', user!.uid)
    const data = await getDoc(userRef)
    const role = data.data()
    state.dispatch({
      type: SignInActionTypes.SIGN_IN_SUCCESS,
      payload: {
        isSignIn: true,
        role: role!.role,
      },
    })
  } catch (e) {
    state.dispatch({
      type: SignInActionTypes.SIGN_IN_ERROR,
      payload: 'Ошибка аутентификации!',
    })
  }
}

export function clearState() {
  return function logIn(dispatch: CallableFunction) {
    dispatch({
      type: SignInActionTypes.SIGN_OUT_SUCCESS,
      payload: {
        isSignIn: false,
        role: undefined,
        loading: false,
        error: '',
      },
    })
  }
}
