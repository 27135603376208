export interface serviceStatusState {
  serviceStatus: string
}

export enum ServiceStatusActionTypes {
  SET_SERVICE_STATUS = 'SET_SERVICE_STATUS',
}

interface SetServiceStatusAction {
  type: ServiceStatusActionTypes.SET_SERVICE_STATUS
  payload: string
}

export type ServiceStatusAction = SetServiceStatusAction
