import {
  CreatePostState,
  CreatePostActions,
  CreatePostActionTypes,
} from '../../../types/createNew'

const initialState: CreatePostState = {
  isCreated: false,
  loading: false,
  error: null,
}

export const createNewReducer = (
  state = initialState,
  action: CreatePostActions
): CreatePostState => {
  switch (action.type) {
    case CreatePostActionTypes.CREATE_POST:
      return { isCreated: false, loading: true, error: null }
    case CreatePostActionTypes.CREATE_POST_SUCCESS:
      return { isCreated: true, loading: false, error: null }
    case CreatePostActionTypes.CREATE_POST_ERROR:
      return { isCreated: false, loading: false, error: action.payload }
    default:
      return state
  }
}
