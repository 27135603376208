export interface ICardsFilters {
  byTop?: boolean
  byOrganization?: string
}

export interface ICard {
  uid: string
  name: string
  owner: string
  createdDate: string
  status: string
  isTopRated: boolean
}

export interface CardsState {
  __cards: Array<ICard>
  cards: Array<ICard>
  loading: boolean
  filters: ICardsFilters
  approveLoading: boolean
  denyLoading: boolean
  deleteLoading: boolean
  statusLoading: boolean
  approveError: string
  denyError: string
  deleteError: string
  statusError: string
  error: string
}

export enum CardsActionTypes {
  FETCH_CARDS = 'FETCH_CARDS',
  FETCH_CARDS_SUCESS = 'FETCH_CARDS_SUCCESS',
  FETCH_CARDS_ERROR = 'FETCH_CARDS_ERROR',
  APPROVE_CARD = 'APPROVE_CARD',
  APPROVE_CARD_SUCCESS = 'APPROVE_CARD_SUCCESS',
  APPROVE_CARD_ERROR = 'APPROVE_CARD_ERROR',
  DENY_CARD = 'DENY_CARD',
  DENY_CARD_SUCCESS = 'DENY_CARD_SUCCESS',
  DENY_CARD_ERROR = 'DENY_CARD_ERROR',
  DELETE_CARD = 'DELETE_CARD',
  DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS',
  DELETE_CARD_ERROR = 'DELETE_CARD_ERROR',
  STATUS_CARD = 'STATUS_CARD',
  STATUS_CARD_SUCCESS = 'STATUS_CARD_SUCCESS',
  STATUS_CARD_ERROR = 'STATUS_CARD_ERROR',
  CARDS_FILTERS = 'CARDS_FILTERS',
}

interface StatusCardAction {
  type: CardsActionTypes.STATUS_CARD
}

interface StatusCardSuccessAction {
  type: CardsActionTypes.STATUS_CARD_SUCCESS
  payload: {
    uid: string
    status: string
    isTopRated: boolean
  }
}

interface StatusCardErrorAction {
  type: CardsActionTypes.STATUS_CARD_ERROR
  payload: string
}

interface DeleteCardAction {
  type: CardsActionTypes.DELETE_CARD
}

interface DeleteCardSuccessAction {
  type: CardsActionTypes.DELETE_CARD_SUCCESS
  payload: string
}

interface DeleteCardErrorAction {
  type: CardsActionTypes.DELETE_CARD_ERROR
  payload: string
}

interface ApproveCardAction {
  type: CardsActionTypes.APPROVE_CARD
}

interface ApproveCardSuccessAction {
  type: CardsActionTypes.APPROVE_CARD_SUCCESS
  payload: string
}

interface ApproveCardErrorAction {
  type: CardsActionTypes.APPROVE_CARD_ERROR
  payload: string
}

interface DenyCardAction {
  type: CardsActionTypes.DENY_CARD
}

interface DenyCardSuccessAction {
  type: CardsActionTypes.DENY_CARD_SUCCESS
  payload: string
}

interface DenyCardErrorAction {
  type: CardsActionTypes.DENY_CARD_ERROR
  payload: string
}

interface CardsAction {
  type: CardsActionTypes.FETCH_CARDS
}

interface CardsActionSuccess {
  type: CardsActionTypes.FETCH_CARDS_SUCESS
  payload: []
}

interface CardsActionError {
  type: CardsActionTypes.FETCH_CARDS_ERROR
  payload: string
}

interface CardsActionFilters {
  type: CardsActionTypes.CARDS_FILTERS
  payload: ICardsFilters
}

export type CardsActions =
  | CardsAction
  | CardsActionSuccess
  | CardsActionError
  | ApproveCardAction
  | ApproveCardSuccessAction
  | ApproveCardErrorAction
  | DenyCardAction
  | DenyCardSuccessAction
  | DenyCardErrorAction
  | DeleteCardAction
  | DeleteCardSuccessAction
  | DeleteCardErrorAction
  | StatusCardAction
  | StatusCardSuccessAction
  | StatusCardErrorAction
  | CardsActionFilters
