import {
  FavouriteActions,
  favouriteActionTypes,
  favouriteState,
} from '../../types/favourites'

const initialState: favouriteState = {
  favourites: [],
  loading: false,
  error: null,
  total: 0,
}

export const favouritesReducer = (
  state = initialState,
  action: FavouriteActions
): favouriteState => {
  switch (action.type) {
    case favouriteActionTypes.FAVOURITES:
      return {
        favourites: state.favourites,
        loading: true,
        error: null,
        total: state.total,
      }
    case favouriteActionTypes.FAVOURITES_SUCCESS: {
      const { items, end, start } = action.payload
      const favourites = items.slice(start, end)

      return {
        favourites,
        loading: false,
        error: null,
        total: state.total,
      }
    }

    case favouriteActionTypes.FAVOURITES_CHANGE_STATUS:
      const { cardId } = action.payload
      return {
        favourites: state.favourites.filter(
          (faourite) => faourite.uid !== cardId
        ),
        loading: false,
        error: null,
        total: state.total - 1,
      }

    case favouriteActionTypes.FAVOURITES_TOTAL:
      return {
        favourites: state.favourites,
        loading: state.loading,
        error: null,
        total: action.payload,
      }

    case favouriteActionTypes.FAVOURITES_ERROR:
      return {
        favourites: [],
        loading: false,
        error: action.payload,
        total: 0,
      }
    default:
      return state
  }
}
