export interface updateUserEmailState {
  isUpdated: boolean
  loading: boolean
  error: null | string
}

export enum updateUserEmailActionTypes {
  UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL',
  UPDATE_USER_EMAIL_SUCCESS = 'UPDATE_USER_EMAIL_SUCCESS',
  UPDATE_USER_EMAIL_ERROR = 'UPDATE_USER_EMAIL_ERROR',
}

interface updateUserEmailAction {
  type: updateUserEmailActionTypes.UPDATE_USER_EMAIL
}

interface updateUserEmailActionSuccess {
  type: updateUserEmailActionTypes.UPDATE_USER_EMAIL_SUCCESS
  payload: boolean
}

interface updateUserEmailActionError {
  type: updateUserEmailActionTypes.UPDATE_USER_EMAIL_ERROR
  payload: string
}

export type updateUserEmailActions =
  | updateUserEmailAction
  | updateUserEmailActionSuccess
  | updateUserEmailActionError
