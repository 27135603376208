import { combineReducers } from 'redux'
import { adminsReducer } from '../../../admin/redux/reducers/admins'
import { cardReducer } from '../../../admin/redux/reducers/card'
import { cardsReducer } from '../../../admin/redux/reducers/cards'
import { createNewReducer } from '../../../admin/redux/reducers/nostate/createNew'
import { accountUserReducer } from './accountUser'
import { cardOwnerInfoReducer } from './cardOwnerInfo'
import { categoryReducer } from './category'
import { favouritesReducer } from './favourites'
import { feedbacksReducer } from './feedbacks'
import { newsReducer } from './news'
import { createAdvertismentReducer } from './nostate/createAdvertisment'
import { createFeedbackReducer } from './nostate/createFeedback'
import { createOrderReducer } from './nostate/createOrder'
import { editAdvertismentReducer } from './nostate/editAdvertisment'
import { signUpReducer } from './nostate/signUp'
import { updateAccountUserReducer } from './nostate/updateAccountUser'
import { updateUserEmailReducer } from './nostate/updateUserEmail'
import { uploadUserPhotoReducer } from './nostate/uploadUserPhoto'
import { ordersReducer } from './orders'
import { ordersStatusReducer } from './ordersStatus'
import { orderItemStatusReducer } from './orderStatus'
import { postReducer } from './post'
import { postsRedcer } from './posts'
import { serviceFeedbacksReducer } from './serviceFeedbacks'
import { servicesReducer } from './services'
import { serviceStatusReducer } from './serviceStatus'
import { signInReducer } from './signIn'
import { topRatedPostsRedcer } from './topRatedPosts'
import { tripsReducer } from './trips'
import { tripsStatusReducer } from './tripsStatus'
import { tripItemStatusReducer } from './tripStatus'

export const rootReducer = combineReducers({
  signIn: signInReducer,
  signUp: signUpReducer,
  category: categoryReducer,
  createAdvertisment: createAdvertismentReducer, // nostate
  topRatedPosts: topRatedPostsRedcer,
  posts: postsRedcer,
  post: postReducer,
  cardOwnerInfo: cardOwnerInfoReducer,
  createFeedback: createFeedbackReducer, //nostate
  editAdvertisment: editAdvertismentReducer, //nostate
  feedbacks: feedbacksReducer,
  serviceStatus: serviceStatusReducer,
  services: servicesReducer,
  ordersStatus: ordersStatusReducer,
  orderItemStatus: orderItemStatusReducer,
  orders: ordersReducer,
  tripsStatus: tripsStatusReducer,
  tripItemStatus: tripItemStatusReducer,
  trips: tripsReducer,
  accountUser: accountUserReducer,
  uploadUserPhoto: uploadUserPhotoReducer, // nostate
  createOrder: createOrderReducer, // nostate
  updateAccountUser: updateAccountUserReducer, // nostate
  updateEmailUser: updateUserEmailReducer, // nostate
  favourites: favouritesReducer,

  serviceFeedbacks: serviceFeedbacksReducer,

  //admin reducers

  admins: adminsReducer,
  createPost: createNewReducer, // nostate
  cards: cardsReducer,
  adminCard: cardReducer,

  // both
  news: newsReducer,
})

export type RootState = ReturnType<typeof rootReducer>
