interface IFavourite {
  favouriteId: string
  images: Array<string>
  name: string
  place: string
  spaceAmount: number
  cost: number
  rating: number
  city: string
  price: number
  owner: string
  uid: string
  isFavourite: boolean
}

export interface favouriteState {
  favourites: Array<IFavourite>
  loading: boolean
  total: number
  error: null | string
}

export enum favouriteActionTypes {
  FAVOURITES = 'FAVOURITES',
  FAVOURITES_SUCCESS = 'FAVOURITES_SUCCESS',
  FAVOURITES_ERROR = 'FAVOURITES_ERROR',
  FAVOURITES_CHANGE_STATUS = 'FAVOURITES_CHANGE_STATUS',
  FAVOURITES_TOTAL = 'FAVOURITES_TOTAL',
}

interface favouriteAction {
  type: favouriteActionTypes.FAVOURITES
}

interface favouritesActionTotal {
  type: favouriteActionTypes.FAVOURITES_TOTAL
  payload: number
}

export interface favoiriteChangeStatus {
  type: favouriteActionTypes.FAVOURITES_CHANGE_STATUS
  payload: {
    cardId: string
  }
}

interface favouriteActionSuccess {
  type: favouriteActionTypes.FAVOURITES_SUCCESS
  payload: {
    items: Array<IFavourite>
    start?: number
    end?: number
  }
}

interface favouriteActionError {
  type: favouriteActionTypes.FAVOURITES_ERROR
  payload: string
}

export type FavouriteActions =
  | favouriteAction
  | favouriteActionSuccess
  | favouriteActionError
  | favoiriteChangeStatus
  | favouritesActionTotal
