import { CreateFeedbackActionSuccess } from './createFeedback'

export interface feedbackState {
  feedbacks: Array<any>
  loading: boolean
  isExistsComment: boolean
  loadingDelete: boolean
  loadingUpdate: boolean
  errorDelete: string
  errorUpdate: string
  error: null | string
}

export enum feedbackActionTypes {
  FEEDBACKS = 'FEEDBACKS',
  FEEDBACKS_SUCCESS = 'FEEDBACKS_SUCCESS',
  FEEDBACKS_ERROR = 'FEEDBACKS_ERROR',
  DELETE_FEEDBACK = 'DELETE_FEEDBACK',
  DELETE_FEEDBACK_SUCCESS = 'DELETE_FEEDBACK_SUCCESS',
  DELETE_FEEDBACK_ERROR = 'DELETE_FEEDBACK_ERROR',
  UPDATE_FEEDBACK = 'UPDATE_FEEDBACK',
  UPDATE_FEEDBACK_SUCCESS = 'UPDATE_FEEDBACK_SUCCESS',
  UPDATE_FEEDBACK_ERROR = 'UPDATE_FEEDBACK_ERROR',
}

interface UpdateFeedBackAction {
  type: feedbackActionTypes.UPDATE_FEEDBACK
}

interface UpdateFeedBackActionSuccess {
  type: feedbackActionTypes.UPDATE_FEEDBACK_SUCCESS
  payload: {
    id: string
    text: string
    rating: number
  }
}

interface UpdateFeedBackActionError {
  type: feedbackActionTypes.UPDATE_FEEDBACK_ERROR
  payload: string
}

interface DeleteFeedBackAction {
  type: feedbackActionTypes.DELETE_FEEDBACK
}

interface DeleteFeedBackActionSuccess {
  type: feedbackActionTypes.DELETE_FEEDBACK_SUCCESS
  payload: string
}

interface DeleteFeedBackActionError {
  type: feedbackActionTypes.DELETE_FEEDBACK_ERROR
  payload: string
}

interface feedbackAction {
  type: feedbackActionTypes.FEEDBACKS
}

interface feedbackActionSuccess {
  type: feedbackActionTypes.FEEDBACKS_SUCCESS
  payload: {
    data: Array<any>
    isExistsComment: boolean
  }
}

interface feedbackActionError {
  type: feedbackActionTypes.FEEDBACKS_ERROR
  payload: string
}

export type FeedbackActions =
  | feedbackAction
  | feedbackActionSuccess
  | feedbackActionError
  | DeleteFeedBackAction
  | DeleteFeedBackActionSuccess
  | DeleteFeedBackActionError
  | UpdateFeedBackAction
  | UpdateFeedBackActionSuccess
  | UpdateFeedBackActionError
  | CreateFeedbackActionSuccess
