import {
  CreateFeedbackActions,
  createFeedbackState,
  CreateFeedbackTypes,
} from '../../../types/createFeedback'

const initialState: createFeedbackState = {
  isCreated: false,
  loading: false,
  error: null,
}

export const createFeedbackReducer = (
  state = initialState,
  action: CreateFeedbackActions
): createFeedbackState => {
  switch (action.type) {
    case CreateFeedbackTypes.CREATE_FEEDBACK:
      return {
        isCreated: false,
        loading: true,
        error: null,
      }
    case CreateFeedbackTypes.CREATE_FEEDBACK_SUCCESS:
      return {
        isCreated: action.payload,
        loading: false,
        error: null,
      }
    case CreateFeedbackTypes.CREATE_FEEDBACK_ERROR:
      return {
        isCreated: false,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
