import { favouriteActionTypes } from '../../types/favourites'
import { postActionTypes, postState, posttActions } from '../../types/post'

const initialState: postState = {
  post: {
    category: '',
    city: '',
    createdDate: '',
    description: '',
    facebook: '',
    images: [],
    instagram: '',
    isTopRated: false,
    manyDays: false,
    name: '',
    oneDay: '',
    owner: '',
    price: 0,
    spaceAmount: 0,
    status: '',
    twitter: '',
    uid: '',
    vk: '',
    withNight: false,
    withTransfer: true,
  },
  loading: false,
  error: null,
}

export const postReducer = (
  state = initialState,
  action: posttActions
): postState => {
  switch (action.type) {
    case postActionTypes.POST:
      return {
        post: {
          category: '',
          city: '',
          createdDate: '',
          description: '',
          facebook: '',
          images: [],
          instagram: '',
          isTopRated: false,
          manyDays: false,
          name: '',
          oneDay: '',
          owner: '',
          price: 0,
          spaceAmount: 0,
          status: '',
          twitter: '',
          uid: '',
          vk: '',
          withNight: false,
          withTransfer: true,
        },
        loading: true,
        error: null,
      }
    case postActionTypes.POST_SUCCESS:
      return { post: action.payload, loading: false, error: null }

    case favouriteActionTypes.FAVOURITES_CHANGE_STATUS: {
      const { cardId } = action.payload
      if (state.post.uid === cardId) {
        state.post.isFavourite = !state.post.isFavourite
      }
      return {
        post: {
          ...state.post,
        },
        loading: false,
        error: null,
      }
    }

    case postActionTypes.POST_ERROR:
      return {
        post: {
          category: '',
          city: '',
          createdDate: '',
          description: '',
          facebook: '',
          images: [],
          instagram: '',
          isTopRated: false,
          manyDays: false,
          name: '',
          oneDay: '',
          owner: '',
          price: 0,
          spaceAmount: 0,
          status: '',
          twitter: '',
          uid: '',
          vk: '',
          withNight: false,
          withTransfer: true,
        },
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
