import {
  updateAccountUserActions,
  updateAccountUserActionTypes,
  updateAccountUserState,
} from '../../../types/updateAccountUser'

const initialState: updateAccountUserState = {
  isUpdated: false,
  loading: false,
  error: null,
}

export const updateAccountUserReducer = (
  state = initialState,
  action: updateAccountUserActions
): updateAccountUserState => {
  switch (action.type) {
    case updateAccountUserActionTypes.UPDATE_ACCOUNT_USER:
      return { isUpdated: false, loading: true, error: null }
    case updateAccountUserActionTypes.UPDATE_ACCOUNT_USER_SUCCESS:
      return { isUpdated: action.payload, loading: false, error: null }
    case updateAccountUserActionTypes.UPDATE_ACCOUNT_USER_ERROR:
      return { isUpdated: false, loading: false, error: action.payload }
    default:
      return state
  }
}
