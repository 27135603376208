import {
  ordersState,
  ordersActions,
  ordersActionTypes,
} from '../../types/orders'

const initialState: ordersState = {
  orders: [],
  loading: false,
  error: null,
  total: 0,
}

export const ordersReducer = (
  state = initialState,
  action: ordersActions
): ordersState => {
  switch (action.type) {
    case ordersActionTypes.ORDERS:
      return {
        orders: state.orders,
        loading: true,
        error: null,
        total: state.total,
      }
    case ordersActionTypes.ORDERS_SUCCESS: {
      const { items, end, start } = action.payload

      const orders = items.slice(start, end)

      return {
        orders,
        loading: false,
        error: null,
        total: state.total,
      }
    }

    case ordersActionTypes.DELETE_ORDER:
      return {
        orders: state.orders.filter((order) => order.id !== action.payload.id),
        loading: false,
        error: null,
        total: state.total - 1,
      }

    case ordersActionTypes.ORDERS_TOTAL:
      return {
        orders: state.orders,
        loading: state.loading,
        error: null,
        total: action.payload,
      }

    case ordersActionTypes.ORDERS_ERROR:
      return {
        orders: [],
        loading: false,
        error: action.payload,
        total: 0,
      }
    default:
      return state
  }
}
