import {
  cardOwnerInfoActions,
  cardOwnerInfoActionTypes,
  cardOwnerInfoState,
} from '../../types/cardOwnerInfo'

const initialState: cardOwnerInfoState = {
  cardOwnerInfo: {
    avatar: '',
    firstName: '',
    number: '',
  },
  loading: false,
  error: null,
}

export const cardOwnerInfoReducer = (
  state = initialState,
  action: cardOwnerInfoActions
): cardOwnerInfoState => {
  switch (action.type) {
    case cardOwnerInfoActionTypes.CARD_OWNER_INFO:
      return {
        cardOwnerInfo: {
          avatar: '',
          firstName: '',
          number: '',
        },
        loading: true,
        error: null,
      }
    case cardOwnerInfoActionTypes.CARD_OWNER_INFO_SUCCESS:
      return {
        cardOwnerInfo: { ...action.payload },
        loading: false,
        error: null,
      }
    case cardOwnerInfoActionTypes.CARD_OWNER_INFO_ERROR:
      return {
        cardOwnerInfo: {
          avatar: '',
          firstName: '',
          number: '',
        },
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
