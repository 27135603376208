export interface serviceFeedbackState {
  serviceFeedbacks: Array<any>
  loading: boolean
  loadingDelete: boolean
  errorDelete: string
  error: string
}

export enum serviceFeedbackActionTypes {
  SERVICE_FEEDBACKS = 'SERVICE_FEEDBACKS',
  SERVICE_FEEDBACKS_SUCCESS = 'SERVICE_FEEDBACKS_SUCCESS',
  SERVICE_FEEDBACKS_ERROR = 'SERVICE_FEEDBACKS_ERROR',
  DELETE_SERVICE_FEEDBACK = 'DELETE_SERVICE_FEEDBACK',
  DELETE_SERVICE_FEEDBACK_SUCCESS = 'DELETE_SERVICE_FEEDBACK_SUCCESS',
  DELETE_SERVICE_FEEDBACK_ERROR = 'DELETE_SERVICE_FEEDBACK_ERROR',
}

interface DeleteFeedBackAction {
  type: serviceFeedbackActionTypes.DELETE_SERVICE_FEEDBACK
}

interface DeleteFeedBackActionSuccess {
  type: serviceFeedbackActionTypes.DELETE_SERVICE_FEEDBACK_SUCCESS
  payload: string
}

interface DeleteFeedBackActionError {
  type: serviceFeedbackActionTypes.DELETE_SERVICE_FEEDBACK_ERROR
  payload: string
}

interface serviceFeedbackAction {
  type: serviceFeedbackActionTypes.SERVICE_FEEDBACKS
}

interface serviceFeedbackActionSuccess {
  type: serviceFeedbackActionTypes.SERVICE_FEEDBACKS_SUCCESS
  payload: {
    data: Array<any>
    isExistsComment: boolean
  }
}

interface serviceFeedbackActionError {
  type: serviceFeedbackActionTypes.SERVICE_FEEDBACKS_ERROR
  payload: string
}

export type ServiceFeedbackActions =
  | serviceFeedbackAction
  | serviceFeedbackActionSuccess
  | serviceFeedbackActionError
  | DeleteFeedBackAction
  | DeleteFeedBackActionSuccess
  | DeleteFeedBackActionError
