import {
  RegisterAction,
  SignUpActionTypes,
  SignUpState,
} from '../../../types/signUp'

const initialState: SignUpState = {
  isSignUp: false,
  loading: false,
  error: null,
}

export const signUpReducer = (
  state = initialState,
  action: RegisterAction
): SignUpState => {
  switch (action.type) {
    case SignUpActionTypes.SIGN_UP:
      return { loading: true, error: null, isSignUp: false }
    case SignUpActionTypes.SIGN_UP_SUCCESS:
      return { loading: false, error: null, isSignUp: action.payload }
    case SignUpActionTypes.SIGN_UP_ERROR:
      return { loading: false, error: action.payload, isSignUp: false }
    default:
      return state
  }
}
