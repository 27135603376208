export interface orderItemStatusState {
  loading: boolean
  error: null | string
}

export enum orderItemStatusActionTypes {
  ORDER_ITEM_STATUS = 'ORDER_ITEM_STATUS',
  ORDER_ITEM_STATUS_ERROR = 'ORDER_ITEM_STATUS_ERROR',
  ORDER_ITEM_STATUS_SUCCESS = 'ORDER_ITEM_STATUS_SUCCESS',
}

interface orderItemStatusAction {
  type: orderItemStatusActionTypes.ORDER_ITEM_STATUS
}

interface orderItemStatusSuccessAction {
  type: orderItemStatusActionTypes.ORDER_ITEM_STATUS_SUCCESS
}

interface orderItemStatusActionError {
  type: orderItemStatusActionTypes.ORDER_ITEM_STATUS_ERROR
  payload: string
}

export type orderItemStatustActions =
  | orderItemStatusAction
  | orderItemStatusActionError
  | orderItemStatusSuccessAction
