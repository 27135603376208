import {
  ServiceFeedbackActions,
  serviceFeedbackActionTypes,
  serviceFeedbackState,
} from '../../types/serviceFeedbacks'

const initialState: serviceFeedbackState = {
  serviceFeedbacks: [],
  loading: false,
  loadingDelete: false,
  errorDelete: '',
  error: '',
}

export const serviceFeedbacksReducer = (
  state = initialState,
  action: ServiceFeedbackActions
): serviceFeedbackState => {
  switch (action.type) {
    case serviceFeedbackActionTypes.SERVICE_FEEDBACKS:
      return { ...state, loading: true }
    case serviceFeedbackActionTypes.SERVICE_FEEDBACKS_SUCCESS:
      return {
        ...state,
        serviceFeedbacks: action.payload.data,
        loading: false,
      }
    case serviceFeedbackActionTypes.SERVICE_FEEDBACKS_ERROR:
      return { ...state, loading: false, error: action.payload }
    case serviceFeedbackActionTypes.DELETE_SERVICE_FEEDBACK:
      return { ...state, loadingDelete: true }
    case serviceFeedbackActionTypes.DELETE_SERVICE_FEEDBACK_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        serviceFeedbacks: state.serviceFeedbacks.filter(
          (el) => el.uid !== action.payload
        ),
      }
    case serviceFeedbackActionTypes.DELETE_SERVICE_FEEDBACK_ERROR:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: action.payload,
      }

    default:
      return state
  }
}
