export interface SignUpState {
  isSignUp: boolean
  loading: boolean
  error: null | string
}

export enum SignUpActionTypes {
  SIGN_UP = 'SIGN_UP',
  SIGN_UP_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_UP_ERROR = 'SIGN_UP_ERROR',
}

interface SignUpAction {
  type: SignUpActionTypes.SIGN_UP
}

interface SignUpActionSuccess {
  type: SignUpActionTypes.SIGN_UP_SUCCESS
  payload: true
}

interface SignUpActionError {
  type: SignUpActionTypes.SIGN_UP_ERROR
  payload: string
}

export type RegisterAction =
  | SignUpAction
  | SignUpActionSuccess
  | SignUpActionError
