export type TypeOrdersPipeline = 'pending' | 'approved' | 'done' | 'rejected'

export interface ordersStatusState {
  ordersStatus: TypeOrdersPipeline
}

export enum OrdersStatusActionTypes {
  SET_ORDERS_STATUS = 'SET_ORDERS_STATUS',
}

interface SetOrdersStatusAction {
  type: OrdersStatusActionTypes.SET_ORDERS_STATUS
  payload: TypeOrdersPipeline
}

export type OrdersStatusAction = SetOrdersStatusAction
