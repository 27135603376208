import React from 'react'
import css from './Loader.module.scss'
import classNames from 'classnames'

interface ILoader {
  className?: string
  dotClassName?: string
  style?: React.CSSProperties
}

const Loader: React.FC<ILoader> = ({ className, style, dotClassName }) => {
  return (
    <div style={style} className={classNames(css.wrapper, className)}>
      <div className={classNames(css.dot1, dotClassName)}></div>
      <div className={classNames(css.dot2, dotClassName)}></div>
      <div className={classNames(css.dot3, dotClassName)}></div>
    </div>
  )
}

export default Loader
