import { TypeTripsPipeline } from './tripsStatus'

export interface tripsState {
  trips: Array<{
    id: string
    images: Array<string>
    cardId: string
    date: string
    finalPrice: number
    personAmount: number
    status: TypeTripsPipeline
    title: string
  }>
  total: number
  loading: boolean
  error: null | string
}

export enum tripsActionTypes {
  TRIPS = 'TRIPS',
  TRIPS_SUCCESS = 'TRIPS_SUCCESS',
  DELETE_TRIP = 'DELETE_TRIP',
  TRIPS_ERROR = 'TRIPS_ERROR',
  TRIPS_TOTAL = 'TRIPS_TOTAL',
}

interface tripsAction {
  type: tripsActionTypes.TRIPS
}

interface tripsActionSuccess {
  type: tripsActionTypes.TRIPS_SUCCESS
  payload: {
    items: []
    start?: number
    end?: number
  }
}

interface tripsActionTotal {
  type: tripsActionTypes.TRIPS_TOTAL
  payload: number
}

interface tripsActionError {
  type: tripsActionTypes.TRIPS_ERROR
  payload: string
}

interface tripsActionDeleteTrip {
  type: tripsActionTypes.DELETE_TRIP
  payload: {
    id: string
  }
}

export type tripsActions =
  | tripsAction
  | tripsActionSuccess
  | tripsActionError
  | tripsActionDeleteTrip
  | tripsActionTotal
