export interface EditAdvertismentState {
  isUpdated: boolean
  loading: boolean
  advertisment?: {
    createdDate: string
    description: string
    facebook: string
    images: Array<string>
    instagram: string
    isTopRated: boolean
    manyDays: boolean
    oneDay: boolean
    owner: string
    price: number
    spaceAmount: number
    status: string
    twitter: string
    uid: string
    vk: string
    category: string
    name: string
    city: string
    withNight: boolean
    withTransfer: boolean
  }
  error: null | string
}

export enum EditAdvertismentActionTypes {
  LOAD_EDIT_ADVERTISMENT = 'LOAD_EDIT_ADVERTISMENT',
  LOAD_EDIT_ADVERTISMENT_SUCCESS = 'LOAD_EDIT_ADVERTISMENT_SUCCESS',
  LOAD_EDIT_ADVERTISMENT_ERROR = 'LOAD_EDIT_ADVERTISMENT_ERROR',
  CLEAR_STATE_EDIT_ADVERTISMENT = 'CLEAR_STATE_EDIT_ADVERTISMENT',
  EDIT_ADVERTISMENT = 'EDIT_ADVERTISMENT',
  EDIT_ADVERTISMENT_SUCCESS = 'EDIT_ADVERTISMENT_SUCCESS',
  EDIT_ADVERTISMENT_ERROR = 'EDIT_ADVERTISMENT_ERROR',
}

interface EditAdvertismentAction {
  type: EditAdvertismentActionTypes.EDIT_ADVERTISMENT
}

interface EditAdvertismentActionSuccess {
  type: EditAdvertismentActionTypes.EDIT_ADVERTISMENT_SUCCESS
  payload: true
}

interface EditAdvertismentActionError {
  type: EditAdvertismentActionTypes.EDIT_ADVERTISMENT_ERROR
  payload: string
}

interface LoadEditAdvertismentAction {
  type: EditAdvertismentActionTypes.LOAD_EDIT_ADVERTISMENT
}

interface LoadEditAdvertismentActionSuccess {
  type: EditAdvertismentActionTypes.LOAD_EDIT_ADVERTISMENT_SUCCESS
  payload: any
}

interface LoadEditAdvertismentActionError {
  type: EditAdvertismentActionTypes.LOAD_EDIT_ADVERTISMENT_ERROR
  payload: string
}

interface ClearStateEditAdvertismentAction {
  type: EditAdvertismentActionTypes.CLEAR_STATE_EDIT_ADVERTISMENT
}

export type editAdvertismentAction =
  | EditAdvertismentAction
  | EditAdvertismentActionSuccess
  | EditAdvertismentActionError
  | LoadEditAdvertismentAction
  | LoadEditAdvertismentActionSuccess
  | LoadEditAdvertismentActionError
  | ClearStateEditAdvertismentAction
