import {
  uploadUserPhotoActions,
  uploadUserPhotoActionTypes,
  uploadUserPhotoState,
} from '../../../types/uploadUserPhoto'

const initialState: uploadUserPhotoState = {
  isCreated: false,
  loading: false,
  error: null,
}

export const uploadUserPhotoReducer = (
  state = initialState,
  action: uploadUserPhotoActions
): uploadUserPhotoState => {
  switch (action.type) {
    case uploadUserPhotoActionTypes.UPLOAD_USER_PHOTO:
      return { isCreated: false, loading: true, error: null }
    case uploadUserPhotoActionTypes.UPLOAD_USER_PHOTO_SUCCESS:
      return { isCreated: action.payload, loading: false, error: null }
    case uploadUserPhotoActionTypes.UPLOAD_USER_PHOTO_ERROR:
      return { isCreated: false, loading: false, error: action.payload }
    default:
      return state
  }
}
