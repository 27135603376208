import { CreateFeedbackTypes } from '../../types/createFeedback'
import {
  FeedbackActions,
  feedbackActionTypes,
  feedbackState,
} from '../../types/feedbacks'

const initialState: feedbackState = {
  feedbacks: [],
  loading: false,
  loadingDelete: false,
  loadingUpdate: false,
  isExistsComment: false,
  errorDelete: '',
  errorUpdate: '',
  error: null,
}

export const feedbacksReducer = (
  state = initialState,
  action: FeedbackActions
): feedbackState => {
  switch (action.type) {
    case feedbackActionTypes.FEEDBACKS:
      return { ...state, loading: true }
    case feedbackActionTypes.FEEDBACKS_SUCCESS:
      return {
        ...state,
        feedbacks: action.payload.data,
        isExistsComment: action.payload.isExistsComment,
        loading: false,
      }
    case feedbackActionTypes.FEEDBACKS_ERROR:
      return { ...state, loading: false, error: action.payload }
    case feedbackActionTypes.DELETE_FEEDBACK:
      return { ...state, loadingDelete: true }
    case feedbackActionTypes.DELETE_FEEDBACK_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        feedbacks: state.feedbacks.filter((el) => el.uid !== action.payload),
        isExistsComment: false,
      }
    case feedbackActionTypes.DELETE_FEEDBACK_ERROR:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: action.payload,
      }
    case feedbackActionTypes.UPDATE_FEEDBACK:
      return { ...state, loadingUpdate: true }

    case feedbackActionTypes.UPDATE_FEEDBACK_SUCCESS:
      state.feedbacks.find((el) => {
        if (el.uid === action.payload.id) {
          el.description = action.payload.text
          el.rating = action.payload.rating
          return
        }
      })

      return {
        ...state,
        loadingUpdate: false,
        feedbacks: [...state.feedbacks],
      }
    case feedbackActionTypes.FEEDBACKS_ERROR:
      return { ...state, loadingUpdate: false, errorUpdate: action.payload }

    case CreateFeedbackTypes.CREATE_FEEDBACK_SUCCESS: {
      return {
        ...state,
        feedbacks: [action.payload, ...state.feedbacks],
        isExistsComment: true,
      }
    }

    default:
      return state
  }
}
