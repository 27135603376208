import { favoiriteChangeStatus } from './favourites'

export type SortingsTypes = 'asc' | 'desc' | 'none'

export type PostsSortsType = {
  price?: SortingsTypes
  reit?: SortingsTypes
}

export type PostsFiltersType = {
  [key: string]: any
  name?: string
  minPrice?: number
  maxPrice?: number
  oneDayTour?: boolean
  manyDaysTour?: boolean
  date?: string
  city?: string
  personAmount?: number
  withNight?: boolean
  withoutNight?: boolean
  withTransfer?: boolean
  withoutTransfer?: boolean
}

export interface postsState {
  posts: Array<unknown>
  __posts: Array<any>
  sorting: PostsSortsType
  filters: PostsFiltersType
  loading: boolean
  loaded: boolean
  error: null | string
}

export enum postsActionTypes {
  POSTS = 'POSTS',
  POSTS_SUCCESS = 'POSTS_SUCCESS',
  POSTS_ERROR = 'POSTS_ERROR',
  POSTS_SET_SORTS = 'POSTS_SET_SORTS',
  POSTS_SET_FILTERS = 'POSTS_SET_FILTERS',
}

interface postsAction {
  type: postsActionTypes.POSTS
}

interface postsActionSuccess {
  type: postsActionTypes.POSTS_SUCCESS
  payload: {
    items: Array<any>
    start: number
    end: number
  }
}

interface postsActionError {
  type: postsActionTypes.POSTS_ERROR
  payload: string
}

interface postsActionSetSorts {
  type: postsActionTypes.POSTS_SET_SORTS
  payload: PostsSortsType
}

interface postsActionSetFiltres {
  type: postsActionTypes.POSTS_SET_FILTERS
  payload: PostsFiltersType
}

export type postsActions =
  | postsAction
  | postsActionSuccess
  | postsActionError
  | postsActionSetSorts
  | postsActionSetFiltres
  | favoiriteChangeStatus
