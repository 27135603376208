import {
  AdminsActions,
  adminsActionTypes,
  AdminsState,
} from '../../types/admins'

const initialState: AdminsState = {
  admins: [],
  loading: false,
  error: null,
}

export const adminsReducer = (state = initialState, action: AdminsActions) => {
  switch (action.type) {
    case adminsActionTypes.ADMINS:
      return { admins: [], loading: true, error: null }
    case adminsActionTypes.ADMINS_SUCCESS:
      return { admins: action.payload, loading: false, error: null }
    case adminsActionTypes.ADMINS_ERROR:
      return { admins: [], loading: false, error: null }
    default:
      return state
  }
}
