import { favoiriteChangeStatus } from './favourites'

export interface postState {
  post: Record<string, any>
  loading: boolean
  error: null | string
}

export enum postActionTypes {
  POST = 'POST',
  POST_SUCCESS = 'POST_SUCCESS',
  POST_ERROR = 'POST_ERROR',
}

interface postAction {
  type: postActionTypes.POST
}

interface postActionSuccess {
  type: postActionTypes.POST_SUCCESS
  payload: Record<string, unknown>
}

interface postActionError {
  type: postActionTypes.POST_ERROR
  payload: string
}

export type posttActions =
  | postAction
  | postActionSuccess
  | postActionError
  | favoiriteChangeStatus
