import { CardActionTypes, CardActions, CardState } from '../../types/card'

const initialState: CardState = {
  loading: false,
  error: '',
  data: {
    withoutNight: false,
    withoutTransfer: false,
    city: '',
    createdDate: '',
    description: '',
    facebook: '',
    images: [],
    instagram: '',
    isTopRated: false,
    manyDays: false,
    name: '',
    oneDay: false,
    price: 0,
    spaceAmount: 0,
    status: '',
    twitter: '',
    uid: '',
    vk: '',
    withNight: false,
    withTransfer: false,
    owner: {
      avatar: '',
      firstName: '',
      number: '',
    },
  },
  ownerError: '',
  ownerLoading: false,
}

export const cardReducer = (
  state = initialState,
  action: CardActions
): CardState => {
  switch (action.type) {
    case CardActionTypes.FETCH_CARD:
      return { ...state, loading: true }

    case CardActionTypes.FETCH_CARD_OWNER:
      return { ...state, ownerLoading: true }

    case CardActionTypes.FETCH_CARD_SUCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: {
          ...action.payload,
          owner: {
            avatar: '',
            firstName: '',
            number: '',
          },
        },
      }

    case CardActionTypes.FETCH_CARD_OWNER_SUCCESS:
      return {
        ...state,
        ownerLoading: false,
        ownerError: '',
        data: {
          ...state.data,
          owner: action.payload,
        },
      }

    case CardActionTypes.FETCH_CARD_ERROR:
      return { ...state, loading: false, error: action.payload }

    case CardActionTypes.FETCH_CARD_OWNER_ERROR:
      return { ...state, ownerLoading: false, ownerError: action.payload }

    default:
      return state
  }
}
