export interface servicesState {
  services: Array<any>
  loading: boolean
  deleteLoading: boolean
  updateLoading: boolean
  stopLoading: boolean
  deleteError: string
  updateError: string
  stopError: string
  error: null | string
  total: number
}

export enum servicesActionTypes {
  SERVICES = 'SERVICES',
  TOTAL_SERVICES = 'TOTAL_SERVICES',
  SERVICES_SUCCESS = 'SERVICES_SUCCESS',
  SERVICES_ERROR = 'SERVICES_ERROR',
  DELETE_SERVICE = 'DELETE_SERVICE',
  DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS',
  DELETE_SERVICE_ERROR = 'DELETE_SERVICE_ERROR',
  STOP_SERVICE = 'STOP_SERVICE',
  STOP_SERVICE_SUCCESS = 'STOP_SERVICE_SUCCESS',
  STOP_SERVICE_ERROR = 'STOP_SERVICE_ERROR',
  UPDATE_SERVICE = 'UPDATE_SERVICE',
  UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS',
  UPDATE_SERVICE_ERROR = 'UPDATE_SERVICE_ERROR',
}

interface StopServiceAction {
  type: servicesActionTypes.STOP_SERVICE
}

interface TotalServiceAction {
  type: servicesActionTypes.TOTAL_SERVICES
  payload: number
}

interface StopServiceActionSuccess {
  type: servicesActionTypes.STOP_SERVICE_SUCCESS
  payload: string
}

interface StopServiceActionError {
  type: servicesActionTypes.STOP_SERVICE_ERROR
  payload: string
}

interface UpdateServiceAction {
  type: servicesActionTypes.UPDATE_SERVICE
}

interface UpdateServiceActionSucces {
  type: servicesActionTypes.UPDATE_SERVICE_SUCCESS
}

interface UpdateServiceActionError {
  type: servicesActionTypes.UPDATE_SERVICE_ERROR
  payload: string
}

interface DeleteServiceAction {
  type: servicesActionTypes.DELETE_SERVICE
}

interface DeleteServiceActionSuccess {
  type: servicesActionTypes.DELETE_SERVICE_SUCCESS
  payload: string
}

interface DeleteServiceActionError {
  type: servicesActionTypes.DELETE_SERVICE_ERROR
  payload: string
}

interface servicesAction {
  type: servicesActionTypes.SERVICES
}

interface servicesActionSuccess {
  type: servicesActionTypes.SERVICES_SUCCESS
  payload: {
    items: Array<any>
    start?: number
    end?: number
  }
}

interface servicesActionError {
  type: servicesActionTypes.SERVICES_ERROR
  payload: string
}

export type servicesActions =
  | servicesAction
  | servicesActionSuccess
  | servicesActionError
  | DeleteServiceAction
  | DeleteServiceActionError
  | DeleteServiceActionSuccess
  | UpdateServiceAction
  | UpdateServiceActionError
  | UpdateServiceActionSucces
  | StopServiceAction
  | StopServiceActionError
  | StopServiceActionSuccess
  | TotalServiceAction
