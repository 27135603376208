import {
  orderItemStatusActionTypes,
  orderItemStatusState,
  orderItemStatustActions,
} from '../../types/orderItemStatus'

const initialState: orderItemStatusState = {
  loading: false,
  error: null,
}

export const orderItemStatusReducer = (
  state = initialState,
  action: orderItemStatustActions
): orderItemStatusState => {
  switch (action.type) {
    case orderItemStatusActionTypes.ORDER_ITEM_STATUS:
      return {
        loading: true,
        error: null,
      }
    case orderItemStatusActionTypes.ORDER_ITEM_STATUS_SUCCESS:
      return {
        loading: false,
        error: null,
      }
    case orderItemStatusActionTypes.ORDER_ITEM_STATUS_ERROR:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
