export interface uploadUserPhotoState {
  isCreated: boolean
  loading: boolean
  error: null | string
}

export enum uploadUserPhotoActionTypes {
  UPLOAD_USER_PHOTO = 'UPLOAD_USER_PHOTO',
  UPLOAD_USER_PHOTO_SUCCESS = 'UPLOAD_USER_PHOTO_SUCCESS',
  UPLOAD_USER_PHOTO_ERROR = 'UPLOAD_USER_PHOTO_ERROR',
}

interface uploadUserPhotoAction {
  type: uploadUserPhotoActionTypes.UPLOAD_USER_PHOTO
}

interface uploadUserPhotoActionSuccess {
  type: uploadUserPhotoActionTypes.UPLOAD_USER_PHOTO_SUCCESS
  payload: boolean
}

interface uploadUserPhotoActionError {
  type: uploadUserPhotoActionTypes.UPLOAD_USER_PHOTO_ERROR
  payload: string
}

export type uploadUserPhotoActions =
  | uploadUserPhotoAction
  | uploadUserPhotoActionSuccess
  | uploadUserPhotoActionError
