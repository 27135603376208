interface INew {
  uid: string
  createdDate: string
  description: string
  images: Array<string>
  name: string
}
export interface newsState {
  news: INew[]
  loading: boolean
  loaded: boolean
  error: null | string
}

export enum newsActionTypes {
  NEWS = 'NEWS',
  NEWS_SUCCESS = 'NEWS_SUCCESS',
  NEWS_ERROR = 'NEWS_ERROR',
  DELETE_NEW = 'DELETE_NEW',
  DELETE_NEW_SUCESS = 'DELETE_NEW_SUCCESS',
  DELETE_NEW_ERROR = 'DELETE_NEW_ERROR',
}

interface newsAction {
  type: newsActionTypes.NEWS
}

interface newsActionSuccess {
  type: newsActionTypes.NEWS_SUCCESS
  payload: {
    items: INew[]
    start: number
    end: number
  }
}

interface newsActionError {
  type: newsActionTypes.NEWS_ERROR
  payload: string
}

interface DeleteNewAction {
  type: newsActionTypes.DELETE_NEW
}

interface DeleteNewActionSuccess {
  type: newsActionTypes.DELETE_NEW_SUCESS
  payload: string
}

interface DeleteNewActionError {
  type: newsActionTypes.DELETE_NEW_ERROR
  payload: string
}

export type newsActions =
  | newsAction
  | newsActionSuccess
  | newsActionError
  | DeleteNewAction
  | DeleteNewActionSuccess
  | DeleteNewActionError
