import { favouriteActionTypes } from '../../types/favourites'
import {
  topRatedPostsActions,
  topRatedPostsActionTypes,
  topRatedPostsState,
} from '../../types/topRatedPosts'

const initialState: topRatedPostsState = {
  posts: [],
  loading: false,
  error: null,
}

export const topRatedPostsRedcer = (
  state = initialState,
  action: topRatedPostsActions
): topRatedPostsState => {
  switch (action.type) {
    case topRatedPostsActionTypes.TOP_RATED_POSTS:
      return { posts: [], loading: true, error: null }
    case topRatedPostsActionTypes.TOP_RATED_POSTS_SUCCESS:
      return { posts: action.payload, loading: false, error: null }
    case topRatedPostsActionTypes.TOP_RATED_POSTS_ERROR:
      return { posts: [], loading: false, error: action.payload }

    case favouriteActionTypes.FAVOURITES_CHANGE_STATUS: {
      const { cardId } = action.payload
      const post: any = state.posts.find((post: any) => post.uid === cardId)

      if (post) {
        post.isFavourite = !post.isFavourite
      }

      return {
        error: null,
        loading: false,
        posts: [...state.posts],
      }
    }

    default:
      return state
  }
}
