export interface tripItemStatusState {
  loading: boolean
  error: null | string
}

export enum tripItemStatusActionTypes {
  TRIP_ITEM_STATUS = 'TRIP_ITEM_STATUS',
  TRIP_ITEM_STATUS_ERROR = 'TRIP_ITEM_STATUS_ERROR',
  TRIP_ITEM_STATUS_SUCCESS = 'TRIP_ITEM_STATUS_SUCCESS',
}

interface tripItemStatusAction {
  type: tripItemStatusActionTypes.TRIP_ITEM_STATUS
}

interface tripItemStatusSuccessAction {
  type: tripItemStatusActionTypes.TRIP_ITEM_STATUS_SUCCESS
}

interface tripItemStatusActionError {
  type: tripItemStatusActionTypes.TRIP_ITEM_STATUS_ERROR
  payload: string
}

export type tripItemStatustActions =
  | tripItemStatusAction
  | tripItemStatusActionError
  | tripItemStatusSuccessAction
