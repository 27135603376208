export type TypeTripsPipeline = 'pending' | 'approved' | 'done' | 'rejected'

export interface tripsStatusState {
  tripsStatus: TypeTripsPipeline
}

export enum TripsStatusActionTypes {
  SET_TRIPS_STATUS = 'SET_TRIPS_STATUS',
}

interface SetTripsStatusAction {
  type: TripsStatusActionTypes.SET_TRIPS_STATUS
  payload: TypeTripsPipeline
}

export type TripsStatusAction = SetTripsStatusAction
