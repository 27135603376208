import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

const App = React.lazy(() => import('./App'))

import { Provider } from 'react-redux'

import { store } from '../redux/index'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase/config/firebase'
import { keepSignIn } from '../redux/actions/signIn'
import Loader from './common/AllLoader'

const getRoleRendered = () => {
  onAuthStateChanged(auth, async (user) => {
    if (user !== null) {
      await keepSignIn(user, store)
    }
    ReactDOM.render(<Wrap />, document.getElementById('root'))
  })
}

const Wrap: React.FC = (): JSX.Element => (
  <HelmetProvider>
    <Provider store={store}>
      <Router>
        <React.StrictMode>
          <Suspense fallback={<Loader style={{ marginTop: '50vh' }} />}>
            <App />
          </Suspense>
        </React.StrictMode>
      </Router>
    </Provider>
  </HelmetProvider>
)

getRoleRendered()
