export interface cardOwnerInfoState {
  cardOwnerInfo: Record<string, unknown>
  loading: boolean
  error: null | string
}

export enum cardOwnerInfoActionTypes {
  CARD_OWNER_INFO = 'CARD_OWNER_INFO',
  CARD_OWNER_INFO_SUCCESS = 'CARD_OWNER_INFO_SUCCESS',
  CARD_OWNER_INFO_ERROR = 'CARD_OWNER_INFO_ERROR',
}

interface cardOwnerInfoAction {
  type: cardOwnerInfoActionTypes.CARD_OWNER_INFO
}

interface cardOwnerInfoActionSuccess {
  type: cardOwnerInfoActionTypes.CARD_OWNER_INFO_SUCCESS
  payload: Record<string, unknown>
}

interface cardOwnerInfoActionError {
  type: cardOwnerInfoActionTypes.CARD_OWNER_INFO_ERROR
  payload: string
}

export type cardOwnerInfoActions =
  | cardOwnerInfoAction
  | cardOwnerInfoActionSuccess
  | cardOwnerInfoActionError
