import { newsActions, newsActionTypes, newsState } from '../../types/news'

const initialState: newsState = {
  news: [],
  loading: false,
  error: null,
}

export const newsReducer = (
  state = initialState,
  action: newsActions
): newsState => {
  switch (action.type) {
    case newsActionTypes.NEWS:
      return { ...state, news: state.news, loading: true, error: null }

    case newsActionTypes.NEWS_SUCCESS: {
      const { items } = action.payload
      return {
        news: [...items],
        loading: false,
        error: null,
        loaded: true,
      }
    }

    case newsActionTypes.NEWS_ERROR:
      return { news: [], loading: false, error: action.payload, loaded: false }

    case newsActionTypes.DELETE_NEW:
      return { ...state, loading: true }

    case newsActionTypes.DELETE_NEW_SUCESS:
      return {
        ...state,
        loading: false,
        news: state.news.filter((el) => el.uid !== action.payload),
      }

    case newsActionTypes.DELETE_NEW_ERROR:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
