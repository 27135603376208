import {
  editAdvertismentAction,
  EditAdvertismentActionTypes,
  EditAdvertismentState,
} from '../../../types/editAdvertisment'

const initialState: EditAdvertismentState = {
  isUpdated: false,
  advertisment: undefined,
  loading: false,
  error: null,
}

export const editAdvertismentReducer = (
  state = initialState,
  action: editAdvertismentAction
): EditAdvertismentState => {
  switch (action.type) {
    case EditAdvertismentActionTypes.LOAD_EDIT_ADVERTISMENT:
      return {
        isUpdated: false,
        loading: true,
        error: null,
        advertisment: undefined,
      }
    case EditAdvertismentActionTypes.LOAD_EDIT_ADVERTISMENT_SUCCESS:
      return {
        isUpdated: false,
        advertisment: action.payload,
        loading: false,
        error: null,
      }
    case EditAdvertismentActionTypes.LOAD_EDIT_ADVERTISMENT_ERROR:
      return {
        isUpdated: false,
        loading: false,
        error: action.payload,
        advertisment: undefined,
      }

    case EditAdvertismentActionTypes.CLEAR_STATE_EDIT_ADVERTISMENT:
      return {
        isUpdated: false,
        loading: false,
        error: null,
        advertisment: undefined,
      }

    case EditAdvertismentActionTypes.EDIT_ADVERTISMENT:
      return { isUpdated: false, loading: true, error: null }
    case EditAdvertismentActionTypes.EDIT_ADVERTISMENT_SUCCESS:
      return { isUpdated: action.payload, loading: false, error: null }
    case EditAdvertismentActionTypes.EDIT_ADVERTISMENT_ERROR:
      return { isUpdated: false, loading: false, error: action.payload }
    default:
      return state
  }
}
