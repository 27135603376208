import { LoginAction, SignInActionTypes, SignInState } from '../../types/signIn'

const initialState: SignInState = {
  isSignIn: false,
  role: undefined,
  loading: false,
  error: null,
}

export const signInReducer = (
  state = initialState,
  action: LoginAction
): SignInState => {
  switch (action.type) {
    case SignInActionTypes.SIGN_IN:
      return {
        ...state,
        loading: true,
      }
    case SignInActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isSignIn: action.payload.isSignIn,
        role: action.payload.role,
      }
    case SignInActionTypes.SIGN_IN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case SignInActionTypes.SIGN_OUT:
      return {
        ...state,
        loading: true,
      }
    case SignInActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
        isSignIn: action.payload.isSignIn,
        role: action.payload.role,
      }
    case SignInActionTypes.SIGN_OUT_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
