import { favoiriteChangeStatus } from './favourites'

export interface topRatedPostsState {
  posts: []
  loading: boolean
  error: null | string
}

export enum topRatedPostsActionTypes {
  TOP_RATED_POSTS = 'TOP_RATED_POSTS',
  TOP_RATED_POSTS_SUCCESS = 'TOP_RATED_POSTS_SUCCESS',
  TOP_RATED_POSTS_ERROR = 'TOP_RATED_POSTS_ERROR',
}

interface topRatedPostsAction {
  type: topRatedPostsActionTypes.TOP_RATED_POSTS
}

interface topRatedPostsActionSuccess {
  type: topRatedPostsActionTypes.TOP_RATED_POSTS_SUCCESS
  payload: []
}

interface topRatedPostsActionError {
  type: topRatedPostsActionTypes.TOP_RATED_POSTS_ERROR
  payload: string
}

export type topRatedPostsActions =
  | topRatedPostsAction
  | topRatedPostsActionSuccess
  | topRatedPostsActionError
  | favoiriteChangeStatus
