import {
  ServiceStatusAction,
  ServiceStatusActionTypes,
  serviceStatusState,
} from '../../types/serviceStatus'

const initialState: serviceStatusState = {
  serviceStatus: 'active',
}

export const serviceStatusReducer = (
  state = initialState,
  action: ServiceStatusAction
): serviceStatusState => {
  switch (action.type) {
    case ServiceStatusActionTypes.SET_SERVICE_STATUS:
      return { serviceStatus: action.payload }
    default:
      return state
  }
}
