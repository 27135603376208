export interface AdminsState {
  admins: []
  loading: boolean
  error: string | null
}

export enum adminsActionTypes {
  ADMINS = 'ADMINS',
  ADMINS_SUCCESS = 'ADMINS_SUCCESS',
  ADMINS_ERROR = 'ADMINS_ERROR',
}

interface AdminsAction {
  type: adminsActionTypes.ADMINS
}

interface AdminsActionSuccess {
  type: adminsActionTypes.ADMINS_SUCCESS
  payload: []
}

interface AdminsActionError {
  type: adminsActionTypes.ADMINS_ERROR
  payload: string
}

export type AdminsActions =
  | AdminsAction
  | AdminsActionSuccess
  | AdminsActionError
