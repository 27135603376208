export interface CreateAdvertismentState {
  isCreated: boolean
  loading: boolean
  error: null | string
}

export enum CreateAdvertismentActionTypes {
  CREATE_ADVERTISMENT = 'CREATE_ADVERTISMENT',
  CREATE_ADVERTISMENT_SUCCESS = 'CREATE_ADVERTISMENT_SUCCESS',
  CREATE_ADVERTISMENT_ERROR = 'CREATE_ADVERTISMENT_ERROR',
}

interface CreateAdvertismentAction {
  type: CreateAdvertismentActionTypes.CREATE_ADVERTISMENT
}

interface CreateAdvertismentActionSuccess {
  type: CreateAdvertismentActionTypes.CREATE_ADVERTISMENT_SUCCESS
  payload: true
}

interface CreateAdvertismentActionError {
  type: CreateAdvertismentActionTypes.CREATE_ADVERTISMENT_ERROR
  payload: string
}

export type createAdvertismentAction =
  | CreateAdvertismentAction
  | CreateAdvertismentActionSuccess
  | CreateAdvertismentActionError
