export interface SignInState {
  isSignIn: boolean
  role: string | undefined
  loading: boolean
  error: null | string
}

export enum SignInActionTypes {
  SIGN_IN = 'SIGN_IN',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_IN_ERROR = 'SIGN_IN_ERROR',
  SIGN_OUT = 'SIGN_OUT',
  SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
  SIGN_OUT_ERROR = 'SIGN_OUT_ERROR',
}

interface SignInAction {
  type: SignInActionTypes.SIGN_IN
}

type SignInSuccessPayload = {
  isSignIn: boolean
  role: string | undefined
}

interface SignInActionSuccess {
  type: SignInActionTypes.SIGN_IN_SUCCESS
  payload: SignInSuccessPayload
}

interface SignInActionError {
  type: SignInActionTypes.SIGN_IN_ERROR
  payload: string
}

interface SignOutAction {
  type: SignInActionTypes.SIGN_OUT
}

type SignOutSuccessPayload = {
  isSignIn: boolean
  role: string | undefined
  loading: boolean
  error: null | string
}

interface SignOutActionSuccess {
  type: SignInActionTypes.SIGN_OUT_SUCCESS
  payload: SignOutSuccessPayload
}

interface SignOutActionError {
  type: SignInActionTypes.SIGN_OUT_ERROR
  payload: string
}

export type LoginAction =
  | SignInAction
  | SignInActionSuccess
  | SignInActionError
  | SignOutAction
  | SignOutActionSuccess
  | SignOutActionError
